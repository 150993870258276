exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jak-dzialam-js": () => import("./../../../src/pages/jak-dzialam.js" /* webpackChunkName: "component---src-pages-jak-dzialam-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-realizacje-index-js": () => import("./../../../src/pages/realizacje/index.js" /* webpackChunkName: "component---src-pages-realizacje-index-js" */),
  "component---src-pages-realizacje-kuchnie-js": () => import("./../../../src/pages/realizacje/kuchnie.js" /* webpackChunkName: "component---src-pages-realizacje-kuchnie-js" */),
  "component---src-pages-realizacje-meble-js": () => import("./../../../src/pages/realizacje/meble.js" /* webpackChunkName: "component---src-pages-realizacje-meble-js" */),
  "component---src-pages-realizacje-portfolio-json-category-portfolio-json-id-work-js": () => import("./../../../src/pages/realizacje/{portfolioJson.category}/{PortfolioJson.id_work}.js" /* webpackChunkName: "component---src-pages-realizacje-portfolio-json-category-portfolio-json-id-work-js" */),
  "component---src-pages-realizacje-szafy-js": () => import("./../../../src/pages/realizacje/szafy.js" /* webpackChunkName: "component---src-pages-realizacje-szafy-js" */)
}

